html,
body {
    font-family: $fontDefaultRegular;
    color: $cWhite;
    cursor: url("../assets/cursor.png"), default;
}

a {
    cursor: url("../assets/hover-cursor.png"), pointer;
}
img,
video,
svg {
    display: block;
    width: 100%;
    height: inherit;
}

.wrapper {
    padding: 0 40px;

    max-width: 1922px;
    width: 100%;
    margin: 0 auto;

    @include mobile {
        padding: 0 20px;
    }
}

.h1,
.h2 {
    font-family: $fontMainBold;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 0.93;
}

.h2 {
    font-size: 120px;

    @include maxHeight850 {
        font-size: 60px;
    }

    @include smallLaptop {
        font-size: 60px;
    }

    @include mobile {
        font-size: 40px;
    }
}

.h3 {
    font-size: 46px;
    font-style: italic;
    color: $w5Transparent;

    @include maxHeight850 {
        font-size: 22px;
    }

    @include smallLaptop {
        font-size: 22px;
    }

    @include mobile {
        font-size: 18px;
    }
}

.basicTitle {
    font-family: $fontMainBold;
    letter-spacing: 0.03em;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 0.8;

    @include maxHeight850 {
        font-size: 60px;
    }

    @include tablet {
        font-size: 60px;
    }

    @include mobile {
        font-size: 29px;
    }

    @include mobileMini {
        font-size: 25px;
    }
}

// buttons
.mainBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    border-radius: 20px;
    background: $cBlue;
    color: $cWhite;
    font-family: $fontDefaultMedium;
    transition: background $timeSm;
    white-space: nowrap;

    @include smallLaptop {
        padding: 9px 26px;
    }

    @include mobile {
        font-size: 14px;
        border-radius: 12px;
    }

    &:hover {
        background: $cBlueHover;
    }

    &__svg {
        margin-left: 8px;
        width: 22px;
        overflow: hidden;

        @include mobile {
            width: 18px;
        }
    }
}
