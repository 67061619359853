@import "../../styles/variables";

.errorPage {
    position: relative;
    height: calc(100vh - 110px);
    width: 100%;

    @media screen and (min-width: 720px) {
        height: calc(100vh - 68px);
    }

    @media screen and (max-height: 479px) {
        height: calc(200vh - 68px);
    }

    &__background {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/svg/404.svg");
        background-repeat: repeat;
        background-size: 150px 50px;
        background-position: -75px 0;
        opacity: 0.06;
        z-index: 1;

        @media screen and (min-width: 1024px) {
            background-position: 0;
        }
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 40px;
        width: 100%;
        height: 100%;
        z-index: 2;
        padding: 36px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 0;
            padding: 100px 100px;
        }
    }

    &__logo {
        position: absolute;
        top: 50px;
        left: 75px;
        width: 150px;
        height: 50px;

        @media screen and (min-width: 1024px) {
            top: 100px;
            left: 150px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 1024px) {
            align-items: flex-start;
        }
    }

    &__animation {
        display: flex;
        align-items: center;
        margin-top: 110px;
        width: 250px;
        height: 250px;

        @media screen and (min-width: 720px) {
            margin-top: 0;
            width: 350px;
            height: 350px;
        }

        @include fromTablet {
            width: 432px;
            height: 432px;
        }
    }
}

.info {
    &__title {
        font-style: italic;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
        text-align: center;
        max-width: 230px;

        @media screen and (min-width: 720px) {
            font-size: 32px;
            max-width: fit-content;
        }

        @media screen and (min-width: 1024px) {
            font-size: 32px;
            line-height: 40px;
            text-align: start;
        }

        @include fromTablet {
            font-size: 50px;
            line-height: 60px;
        }
    }

    &__description {
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.7);
        max-width: 475px;
        margin-bottom: 20px;
        text-align: center;

        @media screen and (min-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
            text-align: start;
            margin-bottom: 40px;
        }

        @include fromTablet {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__button {
        padding: 12px 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        background-color: #41b56f;
        width: 100%;
        border-radius: 20px;
        cursor: url("../../assets/hover-cursor.png"), pointer;

        @media screen and (min-width: 720px) {
            width: fit-content;
        }
    }
}
