@import "../../../styles/variables";

.rico {
    &__wrapper {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
        width: 100%;
        height: 100%;
        border: 1px solid $cPageBorder;
        border-radius: 25px 25px 0px 0px;
        background-color: $cDark;

        @include fromTablet {
            gap: 120px;
        }

        @include mobile {
            gap: 20px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px 80px;

        @include tablet {
            padding: 12px 40px;
        }

        @include mobile {
            padding: 12px 20px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: $w7Transparent;
        font-family: $fontDefaultMedium;
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;

        &-main-svg {
            width: 20px;
            height: 20px;
        }
    }

    &__header {
        display: flex;
        gap: 100px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 80vh;
        width: 100%;
        padding: 0 60px;

        &-svg-block {
            background-image: url("../../../assets/images/rico/rico-header-img.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
        }

        &-info {
            display: flex;
            flex-direction: column;
            width: 70%;
            gap: 24px;
        }

        &-link {
            display: flex;
            align-items: center;
            gap: 12px;
            text-transform: uppercase;
            font-family: $fontMainBold;
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 40px;
            letter-spacing: 0.03em;
        }

        &-svg {
            width: 36px;
            height: 26px;
        }

        &-description {
            font-family: $fontDefaultLight;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: $w4Transparent;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
        }

        &-list-item {
            font-family: $fontDefaultMedium;
            font-size: 14px;
            cursor: default;
            color: rgba($color: #fff, $alpha: 0.4);

            &:nth-child(8) {
                color: $cGreen;
            }
            &:nth-child(9) {
                color: $cGreen;
            }
        }

        &-behance {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            cursor: url("../../../assets/hover-cursor.png"), pointer;

            &-svg {
                width: 24px;
                height: 16px;
            }
        }
    }

    &__animation {
        display: flex;
        align-items: center;
        padding-right: 20px;
    }

    &__stack {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: calc(100% - 120px);
        background: $cThirdBackgroundColor;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        padding: 40px;

        @include tablet {
            width: calc(100% - 100px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-titles {
            display: flex;
            gap: 10px;
            cursor: url("../../../assets/hover-cursor.png"), pointer;
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            font-family: $fontDefaultMedium;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            border: 1px solid rgba(255, 255, 255, 0.06);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.5);

            &.active-stack-title {
                color: $cGreen;
                background: rgba(65, 181, 111, 0.2);
            }

            @include mobile {
                font-size: 14px;
                line-height: 17px;
                padding: 8px 10px;
                width: 100%;
            }
        }

        &-subtitles {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include mobile {
                gap: 10px;
            }
        }

        &-subtitle {
            padding: 20px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.8);

            @include mobile {
                display: flex;
                justify-content: center;
                padding: 30px;
                width: 100%;
            }

            @include minLarge {
                padding: 30px 60px;
            }
        }
    }

    &__about {
        display: flex;
        align-items: center;
        gap: 70px;
        width: calc(100% - 120px);
        height: 75vh;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
            height: fit-content;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            width: 50%;
            height: 100%;

            @include fromTablet {
                transform: translateX(-120%);
            }

            @include tablet {
                width: 100%;
            }
        }

        &-title {
            display: flex;
            font-family: $fontDefaultRegular;
            font-size: 42px;
            line-height: 50px;
            text-transform: uppercase;

            @include mobile {
                font-size: 24px;
                line-height: 29px;
            }

            & h2:first-child {
                color: $w4Transparent;
                opacity: 0.8;
                margin-right: 8px;
            }
        }

        &-description {
            font-family: $fontDefaultRegular;
            font-size: 16px;
            line-height: 25px;
            color: $w4Transparent;

            @include mobile {
                line-height: 19px;
            }
        }

        &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("../../../assets/images/rico/rico-about-background.png");
            width: 50%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include fromTablet {
                transform: translateX(120%);
            }
        }
    }

    &__content {
        display: grid;
        width: calc(100% - 120px);
        height: fit-content;
        grid-template-rows: 1fr 1.5fr;
        margin-bottom: 200px;
        gap: 20px;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &_top {
            display: grid;
            grid-template-columns: 1fr 3fr;
            gap: 20px;
            height: 100%;

            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            &-colors {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            &-colors-block {
                display: flex;
                align-items: center;
                gap: 60px;
                height: 520px;

                @include fromTablet {
                    transform: translateX(140%);
                }

                @include minStandard {
                    gap: 30px;
                }
            }

            &-color-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                color: rgba(255, 255, 255, 0.5);
            }

            &-color {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
                border: 1px solid rgba(255, 255, 255, 0.14);
                border-radius: 6px;

                @include fromTablet {
                    width: 100px;
                    height: 100px;
                }

                @include minStandard {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        &_bottom {
            display: flex;
            gap: 20px;

            &-info-block {
                display: grid;
                grid-template-rows: 1fr 1fr;
                width: 50%;
                gap: 20px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 14px;
                padding: 30px 40px;
            }

            &-info-title {
                font-family: $fontComfortaBold;
                font-size: 30px;
                line-height: 37px;
                color: #fff;
            }

            &-info-description {
                color: rgba(255, 255, 255, 0.6);
                font-family: $fontComfortaRegular;
                font-size: 14px;
                line-height: 25px;

                @include smallLaptop {
                    line-height: 16px;
                }
            }

            &-images {
                display: flex;
                gap: 20px;
                width: 100%;
                height: 100%;
            }

            &-devices-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
            }

            &-devices-img {
                width: 330px;
                height: 670px;
            }
        }
    }
}

.rico__content-top-logo-svg {
    width: 100px;
    height: 100px;
}

.rico-bottom-block {
    background: rgba(255, 255, 255, 0.05);
}

.rico-tang {
    background: #000040;
}

.rico-rich {
    background: #07077b;
}

.rico-penn {
    background: #00ce7d;
}

.rico-delft {
    background: #63e2b0;
}

.rico-light-delft {
    background: #ff9331;
}

.rico-marian {
    background: #ffbe83;
}

.rico__top-logo-svg {
    width: 132px;
    height: 43px;
}

.rico-animate-in {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1s ease-out, opacity 1s ease-out;
}
