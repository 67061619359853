@import "../../../styles/variables";

.moderna-casa {
    &__wrapper {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 120px;
        width: 100%;
        height: 100%;
        border: 1px solid $cPageBorder;
        border-radius: 25px 25px 0px 0px;
        background-color: $cDark;

        @include mobile {
            gap: 20px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px 80px;

        @include mobile {
            padding: 12px 20px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: $w7Transparent;
        font-family: $fontDefaultMedium;
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;

        &-svg {
            width: 20px;
            height: 16px;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 60vh;
        width: 100%;
        padding: 0 60px;

        @include minLarge {
            justify-content: center;
        }

        &-img {
            position: absolute;
            top: 0;
            left: -227px;
            background-image: url("../../../assets/images/moderna/page-header-image.png");
            width: max-content;
            width: 917px;
            height: 60vh;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            @include minLarge {
                position: relative;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            width: 50%;
            height: 100%;
            background-image: url("../../../assets/svg/moderna-casa-logo-opacity.svg");
            background-size: 1000px 500px;
            background-position: center center;
            background-repeat: no-repeat;

            @include minLarge {
                padding: 200px;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            gap: 12px;
            text-transform: uppercase;
            font-family: $fontMainBold;
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 40px;
            letter-spacing: 0.03em;
        }

        &-svg {
            width: 36px;
            height: 26px;
        }

        &-description {
            font-family: $fontDefaultLight;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: rgba($color: #fff, $alpha: 0.9);
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            gap: 6px 8px;
            max-width: 400px;
            margin-bottom: 70px;

            @include tablet {
                margin-bottom: 0;
            }
        }

        &-list-item {
            // padding: 6px 12px;
            // background: $cPageBorder;
            // border-radius: 8px;
            font-family: $fontDefaultMedium;
            font-size: 14px;
            cursor: url("../../../assets/hover-cursor.png"), default;
            color: rgba($color: #fff, $alpha: 0.4);

            &:nth-child(1) {
                color: $cGreen;
            }
            &:nth-child(8) {
                color: $cGreen;
            }
            &:nth-child(9) {
                color: $cGreen;
            }
        }

        &-behance {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            cursor: url("../../../assets/hover-cursor.png"), pointer;

            &-svg {
                width: 24px;
                height: 16px;
            }
        }
    }

    &__stack {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: calc(100% - 120px);
        background: $cThirdBackgroundColor;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        padding: 40px;

        @include tablet {
            width: calc(100% - 100px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-titles {
            display: flex;
            gap: 10px;
            cursor: url("../../../assets/hover-cursor.png"), pointer;

            @include mobile {
                justify-content: space-between;
            }
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            font-family: $fontDefaultMedium;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            border: 1px solid rgba(255, 255, 255, 0.06);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.5);

            &.active-stack-title {
                color: $cGreen;
                background: rgba(65, 181, 111, 0.2);
            }

            @include mobile {
                font-size: 14px;
                line-height: 17px;
                padding: 8px 10px;
                width: 100%;
            }
        }

        &-subtitles {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include mobile {
                gap: 10px;
            }
        }

        &-subtitle {
            padding: 20px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.8);

            @include minLarge {
                padding: 30px 60px;
            }

            @include mobile {
                display: flex;
                justify-content: center;
                padding: 30px;
                width: 100%;
            }
        }
    }

    &__about {
        display: flex;
        align-items: center;
        gap: 70px;
        width: calc(100% - 120px);
        height: 75vh;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
            height: fit-content;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;
            height: 100%;

            @include tablet {
                width: 100%;
            }

            @include fromTablet {
                transform: translateX(-120%);
            }
        }

        &-title {
            display: flex;
            font-family: $fontDefaultRegular;
            font-size: 42px;
            line-height: 50px;
            text-transform: uppercase;

            @include mobile {
                font-size: 24px;
                line-height: 29px;
            }

            & h2:first-child {
                color: $w4Transparent;
                opacity: 0.8;
                margin-right: 8px;
            }
        }

        &-description {
            font-family: $fontDefaultRegular;
            font-size: 16px;
            line-height: 25px;
            color: $w4Transparent;

            @include mobile {
                line-height: 19px;
            }
        }

        &-img {
            background-image: url("../../../assets/images/moderna/about-modernacasa.jpg");
            width: max-content;
            width: 50%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            @include fromTablet {
                transform: translateX(120%);
            }
        }
    }

    &__content {
        display: grid;
        width: calc(100% - 120px);
        height: fit-content;
        grid-template-rows: 1fr 2fr;
        margin-bottom: 200px;
        gap: 20px;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &_top {
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            gap: 20px;
            height: 100%;

            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-logo-svg {
                width: 116px;
                height: 100px;
            }

            &-colors {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            &-colors-block {
                display: flex;
                align-items: center;
                gap: 60px;
                height: 320px;

                @include fromTablet {
                    transform: translateX(200%);
                }
            }

            &-color-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                color: rgba(255, 255, 255, 0.5);
            }

            &-color {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                border: 1px solid rgba(255, 255, 255, 0.14);
                border-radius: 6px;
            }

            &-img {
                background-image: url("../../../assets/images/moderna/moderna-bottom-1.jpg");
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                cursor: url("../../../assets/hover-cursor.png"), pointer;
                transition: 0.4s transform ease;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        &_bottom {
            display: flex;
            gap: 20px;

            &-info-block {
                display: grid;
                grid-template-rows: 1fr 1fr;
                width: 50%;
                gap: 20px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                gap: 14px;
                padding: 30px 40px;
            }

            &-info-title {
                font-family: $fontMontseratBold;
                font-size: 30px;
                line-height: 37px;
                color: #fff;
            }

            &-info-description {
                color: rgba(255, 255, 255, 0.6);
                font-family: $fontOverpassRegular;
                font-size: 14px;
                line-height: 25px;

                @include smallLaptop {
                    line-height: 16px;
                }
            }

            &-images {
                display: flex;
                gap: 20px;
                width: 100%;
                height: 100%;
            }

            &-first-img {
                background-image: url("../../../assets/images/moderna/moderna-bottom-2.jpg");
                width: 50%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                cursor: url("../../../assets/hover-cursor.png"), pointer;
                transition: 0.4s transform ease;

                &:hover {
                    transform: scale(1.03);
                }
            }

            &-second-img {
                background-image: url("../../../assets/images/moderna/moderna-bottom-3.jpg");
                width: 50%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                cursor: url("../../../assets/hover-cursor.png"), pointer;
                transition: 0.4s transform ease;

                &:hover {
                    transform: scale(1.03);
                }
            }

            &-devices-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
            }

            &-devices-img {
                width: 436px;
                height: 386px;
            }
        }
    }
}

.moderna-bottom-block {
    background: rgba(255, 255, 255, 0.05);
}

.moderna-onyx {
    background: #323436;
    color: #fff;
}

.moderna-maize {
    background: #ffe662;
    color: #282829;
}

.moderna-black {
    background: #000;
}

.moderna-animate-in {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1s ease-out, opacity 1s ease-out;
}

.moderna-casa__top-svg {
    width: 50%;
    height: 50%;
}
