@import "../../styles/variables";

.loader {
    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        background-color: #0d0f0e;
        z-index: 10000;
    }

    &__animation {
        height: 130px;
        width: 190px;
    }
}
