@import "../../styles/variables";

.formLabel__check,
.formLabel__fail {
    display: none;
    width: 22px;
    width: 22px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.formPageNav {
    display: flex;
}

.formPage {
    &__step {
        padding: 18px 0 6px;
        color: rgba($color: #fff, $alpha: 0.2);
        margin-right: 20px;
        // font-size: 14px;
        // font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;

        pointer-events: all;

        @include mobile {
            margin-right: 8px;
            font-size: 12px;

            &:last-child {
                margin-right: 0;
            }
        }

        &-svg {
            display: none;
            // margin-top: 10px;
            margin-left: 10px;
            width: 15px;
            // position: absolute;
            // bottom: -16px;
            // left: 50%;
            // transform: translateX(-50%);
        }

        &.active {
            color: #41b56f;
            pointer-events: none;
        }

        &.filled {
            .formPage__step-svg {
                display: block;
            }
        }
    }

    > .wrapper {
        min-height: calc(100vh - 90px);
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-bottom: 150px;

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include smallLaptop {
            min-height: unset;
        }
    }

    &__title {
        text-align: center;
        color: #fff;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        font-size: 42px;
        font-family: $fontDefaultMedium;
        font-style: italic;
        margin: 34px 0 40px;

        @include tablet {
            font-size: 32px;
        }

        @include mobile {
            font-size: 22px;
        }
    }

    .client-title {
        margin: 20px 0;
    }

    .mainBtn {
        min-width: 165px;
        padding: 13px 26px;

        @include mobile {
            min-width: unset;
            width: calc(50% - 5px);
            // margin-top: 20px;
        }

        &.remove-button {
            display: none;
        }

        &--back {
            background: #101011;
            border: 1px solid #222222;

            &:hover {
                background: #101011;
            }
        }

        &--continue {
            background: #222222;
            color: #41b56f;
            margin-left: auto;
            margin-left: auto;

            &:hover {
                background: #222222;
            }
        }
    }
}

.actionForm {
    width: 100%;
    max-width: 720px;
    margin: auto;

    &__fieldBlock {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 30px;

        .MuiFormControl-root {
            margin-bottom: 0 !important;
        }

        @media screen and (min-width: 720px) {
            gap: 20px;
        }
    }

    &__inputField {
        width: 100%;

        @media screen and (min-width: 720px) {
            width: calc(50vw - 50px);
        }

        @media screen and (min-width: 800px) {
            width: 350px;
        }
    }

    &__privacyWrapper {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 25px;

        @media screen and (min-width: 720px) {
            align-items: flex-start;
        }

        span {
            line-height: 22px;
            font-weight: 400;
            font-size: 12px;
            color: #808080;

            @media screen and (min-width: 720px) {
                font-size: 16px;
            }

            a {
                color: #41b56f;
                text-decoration: underline;
                cursor: url("../../assets/hover-cursor.png"), pointer;
            }
        }
    }

    &__submitButton {
        color: #41b56f !important;
        font-family: $fontDefaultRegular !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        background: #222222;
        border-radius: 20px;
        text-transform: capitalize;
        padding: 12px 60px;
        cursor: url("../../assets/hover-cursor.png"), pointer;

        &:disabled {
            color: gray !important;
        }

        &:hover {
            background: #494949;
        }
    }
}

.finalFormBlock {
    margin: auto;
    max-width: 765px;

    @include mobile {
        margin-bottom: 30px;

        .formField {
            margin-bottom: 10px;
        }
    }
}

.formField {
    &__radioGroup {
        margin-bottom: 30px;

        
    }

    @include mobile {
        margin-bottom: 0;
        font-size: 14px;

        &--mobile {
            @include mobile {
                margin-bottom: 20px;
            }
        }

        &--mobile {
            @include mobile {
                margin-bottom: 20px;
            }
        }
    }

    &--flex {
        display: flex;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }
    }

    &--smallMargin {
        min-height: 85px;
        height: fit-content;
    }

    &__btnMore {
        width: 56px;
        height: 56px;
        background: #222222;
        border-radius: 20px;
        flex-shrink: 0;
        position: relative;

        @include mobile {
            width: 100%;
            height: 37px;
        }

        &::after,
        &::before {
            content: "";
            display: block;
            width: 18px;
            height: 3px;
            border-radius: 10px;
            background: #ffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &--remove {
            &::after {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__textarea {
        resize: none;
        width: 100%;
        height: 110px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(22, 22, 22, 0.7);
        padding: 15px 20px;
        color: rgba(255, 255, 255, 1);
        line-height: 150%;
        margin-bottom: 30px;

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.7);
        }

        &:focus {
            border: 1px solid #41b56f;
        }
    }
}

.formFieldInfo {
    margin-top: 1rem;

    @include mobile {
        margin: 30px 0;

        .formField {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: 20px;
        font-style: italic;

        &-block {
            font-family: $fontDefaultMedium;

            font-size: 18px;
            margin-bottom: 7px;

            @include mobile {
                font-size: 16px;
            }
        }

        &-desk {
            color: #808080;

            @include mobile {
                max-width: 285px;
            }
        }
    }
}

.formLabel {
    background: rgba(22, 22, 22, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    color: #808080;
    padding: 20px;
    overflow: hidden;
    width: 100%;
    margin-right: 20px;
    position: relative;
    cursor: text;
    height: 63px;
    display: flex;
    align-items: center;

    @include mobile {
        height: 56px;
        height: 56px;
        margin-right: 0;
        margin-bottom: 10px;
        padding: 16px 20px 17px;
    }

    &:last-child {
        margin-right: 0;

        @include mobile {
            margin-bottom: 0;
        }
    }

    input {
        color: #808080;
        position: absolute;
        bottom: 8px;
        left: 20px;
        width: calc(100% - 40px);
        color: #ffff;

        &::placeholder {
            color: red;
        }

        &.PhoneInputInput {
            position: static;
        }

        &.PhoneInputInput {
            position: static;
        }
    }

    &__name {
        transform: translateY(0);
        transform: translateY(0);
        display: inline-block;
        transition: 0.3s;
        user-select: none;
    }

    &.invalid {
        border-color: #f04a4a;

        .formLabel__fail {
            display: block;
        }
    }

    &.onInput {
        border-color: #41b56f;

        .formLabel__fail {
            display: none;
        }

        input {
            color: #ffff;
        }

        .formLabel__name {
            transform: translateY(-10px);

            &::after {
                opacity: 0;
            }
        }
    }

    &.checked {
        border-color: rgba(255, 255, 255, 0.2);

        .formLabel__check {
            display: block;
        }
    }

    &__error {
        font-size: 12px;
        color: #f04a4a;
        // position: absolute;
        // top: 50%;
        // right: 20px;
        // transform: translateY(-50%);
    }
}

.required {
    .formLabel__name {
        &::after {
            content: "*";
            color: rgba(65, 181, 111, 0.8);
            display: inline-block;
            font-size: 21px;
            margin-left: 2px;
        }
    }

    .css-1xb54ij-placeholder {
        width: fit-content;

        &::after {
            content: "*";
            color: rgba(65, 181, 111, 0.8);
            display: inline-block;
            font-size: 21px;
            margin-left: 2px;
        }
    }
}

.required {
    .formLabel__name {
        &::after {
            content: "*";
            color: rgba(65, 181, 111, 0.8);
            display: inline-block;
            font-size: 21px;
            margin-left: 2px;
        }
    }

    .css-1xb54ij-placeholder {
        width: fit-content;

        &::after {
            content: "*";
            color: rgba(65, 181, 111, 0.8);
            display: inline-block;
            font-size: 21px;
            margin-left: 2px;
        }
    }
}

.formRadio {
    padding: 15px;
    // width: calc(100% / 3 - 40px);
    text-align: center;
    margin-right: 10px;
    white-space: nowrap;
    cursor: url("../../assets/hover-cursor.png"), pointer;

    @include mobile {
        margin-right: 0;
        margin-bottom: 10px;
    }

    &:last-child {
        margin-right: 0;
    }

    &__name {
        color: #ffffff;
        margin-right: 6px;
    }

    input:checked {
        ~ span {
            color: #41b56f;
        }
    }
}

.formActions {
    display: flex;
    justify-content: space-between;
}

.formSelect {
    position: relative;
    cursor: url("../../assets/hover-cursor.png"), pointer;
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 19px 0 20px;
    height: 62px;
    border: 1px solid rgba(255, 255, 255, 0.2);

    @include mobile {
        margin-right: 0;
        margin-bottom: 10px;
        padding: 16px 0;
        height: 56px;
    }

    &:last-child {
        margin-right: 0;
    }

    &__control {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #808080;
    }

    &__menu {
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        display: block;
        width: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        z-index: 99999;
        width: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        z-index: 99999;

        padding: 16px;
        background: #101011;
        border: 1px solid #434343;
        border-radius: 16px;
        padding: 16px;
        background: #101011;
        border: 1px solid #434343;
        border-radius: 16px;
    }

    &__option {
        padding: 8px;
        cursor: url("../../assets/hover-cursor.png"), pointer;
        &:hover {
            color: #fff;
        }
    }

    &__indicators {
        width: 20px;
    }

    &__indicators {
        width: 20px;
    }
}

.invalid {
    border-color: #f04a4a;
}

.disabled {
    // border-color: #f04a4a;
    pointer-events: none;
    opacity: 0.2;
}

.formField--selection {
    @include mobile {
        margin-top: 10px;
    }

    @include mobile {
        margin-top: 10px;
    }

    .formSelect__menu {
        height: 252px;

        &-list {
            height: 100%;
            overflow: auto;

            scrollbar-width: thin;
            scrollbar-color: #41b56f #1f1f20;
            scrollbar-width: thin;
            scrollbar-color: #41b56f #1f1f20;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                // display: none;
                background: #1f1f20;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                // display: none;
                background: #41b56f;
                border-radius: 10px;
            }
        }
    }
}

.dropFileLabel {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 20px;
    cursor: url("../../assets/hover-cursor.png"), pointer;
    border: 1px dashed rgba(255, 255, 255, 0.2);

    @include mobile {
        margin-bottom: 10px;
        height: 80px;
    }

    .formLabel__name {
        color: rgba(255, 255, 255, 0.8);
    }

    input {
        // position: relative;
        // background: rgba(22, 22, 22, 1);
        // border: 1px solid rgba(255, 255, 255, 0.2);
        // border-radius: 16px;
        color: rgba(255, 255, 255, 0.8);
        padding: 14px 0;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: url("../../assets/hover-cursor.png"), pointer;
        width: auto;
        z-index: 0;
        font-size: 0;

        @include mobile {
            padding: 5px;
        }

        &::-webkit-file-upload-button {
            visibility: hidden;
        }
    }
}

.formField--english {
    .formSelect__placeholder {
        max-width: 225px;

        .formField--english {
            .formSelect__placeholder {
                max-width: 225px;
            }
        }

        .css-1hb7zxy-IndicatorsContainer {
            color: #808080;
        }

        .css-tj5bde-Svg {
            fill: #808080;
        }

        .PhoneInputInternationalIconPhone {
            // display: none;
        }

        .PhoneInputCountrySelectArrow {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #ffff;
            transform: rotate(180deg);
        }
    }
}

.formField--client {
    .formLabel,
    .formSelect {
        margin-bottom: 30px;

        @include mobile {
            margin-bottom: 10px;
        }
    }

    &.formField {
        margin-bottom: 40px;

        @include mobile {
            margin-bottom: 30px;
        }
    }
}

.css-1705783-DropdownIndicator {
    color: #808080;
}

.submitMessage {
    &__block {
        background: #222222;
        border: 1px solid rgba(65, 181, 111, 0.3);
        box-shadow: 0px 0px 50px rgba(65, 181, 111, 0.25);
        border-radius: 10px;
        max-width: 760px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 60px;

        @include tablet {
            padding: 20px;
        }
    }

    &__img {
        width: 200px;
        margin: 0 auto 30px;
    }

    &__json {
        width: 400px;
        margin: 0 auto 30px;
    }

    &__action {
        margin: 2rem auto;
    }

    &__title {
        font-size: 30px;
        font-style: italic;
        font-family: $fontDefaultMedium;
        letter-spacing: -0.04em;
        text-transform: uppercase;

        @include tablet {
            font-size: 16px;
        }
    }
}

.privacyCheckbox {
    position: relative;
}

.privacyCheckbox label {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(22, 22, 22, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    height: 22px;
    width: 22px;
    cursor: url("../../assets/hover-cursor.png"), pointer;
}

.privacyCheckbox label:after {
    border: 2px solid white;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 11px;
}

.privacyCheckbox input[type="checkbox"] {
    visibility: hidden;
}

.privacyCheckbox input[type="checkbox"]:checked + label {
    background-color: #41b56f;
    border-color: #41b56f;
}

.privacyCheckbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.checkbox-group-box-wrapper{
 display: flex;
 gap: 10px;
 @include mobile {
    flex-direction: column;
 }
}

.green-asterisk {
    color: #41B56FCC;
    font-size: 20px;
}
.red-asterisk{
    color: #f04a4a;
}