@import "../../styles/variables";

.page {
    &_wrapper {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        height: 100%;
        border: 1px solid $cPageBorder;
        border-radius: 25px 25px 0px 0px;
        background-color: $cSecondaryBackgroundColor;
    }

    &_title {
        padding: 12px 40px;
        font-family: $fontMainBold;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: $w7Transparent;
        cursor: url("../../assets/hover-cursor.png"), pointer;
    }
}
