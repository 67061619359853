.development {
    &__content {
        display: flex;
    }

    &__content-schema {
        position: relative;
        width: 700px;
    }
    &__content-btn {
        width: 320px;

        padding: 12px 40px;
        border-radius: 8px;
        border: 1px solid rgba(65, 181, 111, 0.4);
        background: rgba(65, 181, 111, 0.1);

        color: #41b56f;
        text-align: center;
        font-size: 14px;
        font-family: "InterMedium", sans-serif;
        font-weight: 500;
        line-height: 150%;
    }
}

.content-schema {
    &__connetcion-wrapper {
        top: 80px;
        right: 100px;
        position: absolute;
        opacity: 0;
    }
}
