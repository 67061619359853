@import "../../../styles/variables";

.uiux {
    &__navigation {
        position: relative;
        margin-bottom: 80px;

        & .MuiBottomNavigation-root {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            padding: 10px 20px;
            background-color: #0d0f0e;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;

            & .MuiButtonBase-root {
                padding: 8px 16px;
                color: #fff;
                background: #161616;
                border: 1px solid rgba(255, 255, 255, 0.06);
                border-radius: 8px;
                max-width: fit-content;
                cursor: url("../../../assets/hover-cursor.png"), pointer;
                @include mobile {
                    max-width: 100%;
                }
            }

            & button.Mui-selected {
                background: rgba(255, 255, 255, 0.2);
            }
        }

        &-icon {
            position: absolute;
            top: 13px;
            right: 20px;
            width: 30px;
            height: 30px;

            @include mobile {
                display: none;
            }

            & path {
                fill: #3d3f3e;
            }
        }
    }
}
