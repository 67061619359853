@import "../../styles/variables";

.layerTitle {
    padding: 0.5rem 1.5rem;
    text-align: left;
    font-size: 16px;
    font-family: $fontMainBold;
    line-height: 32px;
    cursor: url("../../assets/hover-cursor.png"), pointer;
}

.layerHeaderBox {
    -webkit-transition: transform 0.2s ease 0s;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transition: transform 0.2s ease 0s;
}

.layerHeaderBox:hover {
    transform: translateY(-24px);
    -webkit-transform: translateY(-24px);
}
