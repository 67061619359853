@import "../../../../../styles/variables";

.technology {
    &__wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        @include maxWidth850 {
            align-items: center;
            flex-direction: column;
            gap: 50px;
        }
    }

    &__right-side {
        width: 50%;

        @include smallLaptop {
            width: 100%;
        }
    }

    &__title {
        position: sticky !important;
        top: 110px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-style: italic;
        font-weight: 500;
        font-size: 42px;
        line-height: 51px;
        text-transform: uppercase;
        width: fit-content;
        padding-left: 24px;

        @include maxWidth850 {
            padding-left: 0;
            width: 100%;
            text-align: center;
        }

        @include mobileMiddle {
            font-size: 40px;
            line-height: 100%;
        }

        @include smallLaptop {
            top: 90px;
            width: fit-content;
        }

        @include mobile {
            position: static !important;
            top: unset;
        }

        span {
            &:first-child {
                font-size: 16px;
                line-height: 19px;
                color: #41b56f;
                padding-left: 4px;
            }

            &:last-child {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;
                opacity: 0.4;
                text-transform: lowercase;
            }
        }
    }

    &__icons {
        width: 50%;

        @include maxWidth850 {
            padding-left: 0;
            width: 100%;
        }
    }
}

.icon-block__row {
    &.not-centered-row {
        width: 100%;
    }
}
