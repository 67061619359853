@import "../../../styles/variables";

.alienclick {
    &__wrapper {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
        width: 100%;
        height: 100%;
        border: 1px solid $cPageBorder;
        border-radius: 25px 25px 0px 0px;
        background-color: $cDark;

        @include fromTablet {
            gap: 120px;
        }

        @include mobile {
            gap: 20px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px 80px;

        @include mobile {
            padding: 12px 20px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: $w7Transparent;
        font-family: $fontDefaultMedium;
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;

        &-main-svg {
            width: 35px;
            height: 35px;
        }

        &-svg {
            width: 20px;
            height: 17px;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 60vh;
        width: 100%;
        padding: 0 60px;

        @include minLarge {
            justify-content: center;
        }

        &-video {
            position: absolute;
            top: 0;
            left: -227px;

            width: max-content;
            width: 917px;
            height: 60vh;

            @include minLarge {
                position: relative;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 50%;

            @include minLarge {
                padding: 200px;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            gap: 12px;
            text-transform: uppercase;
            font-family: $fontMainBold;
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 40px;
            letter-spacing: 0.03em;
        }

        &-svg {
            width: 36px;
            height: 26px;
        }

        &-description {
            font-family: $fontDefaultLight;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: $w4Transparent;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
        }

        &-list-item {
            font-family: $fontDefaultMedium;
            font-size: 14px;
            cursor: url("../../../assets/hover-cursor.png"), default;
            color: #ffffff;
            padding: 5px 10px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            &:nth-child(1) {
                color: $cGreen;
            }
        }

        &-behance {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            cursor: url("../../../assets/hover-cursor.png"), pointer;

            &-svg {
                width: 24px;
                height: 16px;
            }
        }
    }

    &__animation {
        display: flex;
        align-items: center;
        gap: 20vh;
        font-weight: $fontBaiJamjureeLightItalic;
        padding-right: 20px;
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;

        &-img {
            height: 30px;
            width: 100%;
        }

        &-svg {
            width: 100%;
            height: 60px;
        }
    }

    &__stack {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: calc(100% - 120px);
        background: $cThirdBackgroundColor;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        padding: 40px;

        @include tablet {
            width: calc(100% - 100px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-titles {
            display: flex;
            gap: 10px;
            cursor: url("../../../assets/hover-cursor.png"), pointer;

            @include mobile {
                justify-content: space-between;
            }
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            font-family: $fontDefaultMedium;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            border: 1px solid rgba(255, 255, 255, 0.06);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.5);

            &.active-stack-title {
                color: $cGreen;
                background: rgba(65, 181, 111, 0.2);
            }

            @include mobile {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 17px;
                padding: 8px 10px;
                width: 100%;
            }
        }

        &-subtitles {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @include mobile {
                gap: 10px;
            }
        }

        &-subtitle {
            padding: 20px;
            font-family: $fontDefaultMedium;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.8);

            @include mobile {
                display: flex;
                justify-content: center;
                padding: 30px;
                width: 100%;
            }

            @include minLarge {
                padding: 30px 60px;
            }
        }
    }

    &__about {
        display: flex;
        align-items: center;
        gap: 70px;
        width: calc(100% - 120px);
        height: 70vh;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
            height: fit-content;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            width: 50%;
            height: 100%;

            @include fromTablet {
                transform: translateX(-120%);
            }

            @include tablet {
                width: 100%;
            }
        }

        &-title {
            display: flex;
            font-family: $fontDefaultRegular;
            font-size: 42px;
            line-height: 50px;
            text-transform: uppercase;

            @include mobile {
                font-size: 24px;
                line-height: 29px;
            }

            & h2:first-child {
                color: $w4Transparent;
                opacity: 0.8;
                margin-right: 8px;
            }
        }

        &-description {
            font-family: $fontDefaultRegular;
            font-size: 16px;
            line-height: 25px;
            color: $w4Transparent;

            @include mobile {
                line-height: 19px;
            }
        }

        &-video {
            position: relative;
            width: 40%;
            height: fit-content;

            @include tablet {
                display: none;
            }

            video {
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }

            @include fromTablet {
                transform: translateX(120%);
            }
        }
    }

    &__content {
        display: grid;
        width: calc(100% - 120px);
        height: fit-content;
        grid-template-rows: 1fr 1.5fr;
        margin-bottom: 200px;
        gap: 20px;

        @include tablet {
            width: calc(100% - 80px);
            gap: 12px;
            padding: 18px;
        }

        @include mobile {
            width: calc(100% - 40px);
            gap: 12px;
            padding: 18px;
        }

        &_top {
            display: grid;
            grid-template-columns: 1fr 3fr;
            gap: 20px;
            height: 100%;

            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-logo-svg {
                width: 20%;
                @media screen and (min-width: $mobile) {
                    width: 50%;
                }
            }

            &-colors {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            &-colors-block {
                display: flex;
                align-items: center;
                gap: 60px;
                height: 520px;

                @include fromTablet {
                    transform: translateX(140%);
                }
            }

            &-color-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                color: rgba(255, 255, 255, 0.5);
            }

            &-color {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
                border: 1px solid rgba(255, 255, 255, 0.14);
                border-radius: 6px;

                @include minStandard {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        &_bottom {
            display: flex;
            gap: 20px;

            &-info-block {
                display: grid;
                grid-template-rows: 1fr 1fr;
                width: 50%;
                gap: 20px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 14px;
                padding: 30px 40px;
            }

            &-info-title {
                font-family: "JetBrains Mono";
                font-style: italic;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 30px;
                line-height: 40px;
                color: #ffffff;
            }

            &-info-description {
                font-family: "JetBrains Mono";
                font-style: italic;
                color: rgba(255, 255, 255, 0.6);
                font-size: 14px;
                line-height: 21px;
                max-width: 570px;
            }

            &-images {
                display: flex;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.05);
            }

            &-list {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 30px 60px;
                padding: 30px 90px;
            }

            &-item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-basis: calc(100% / 4 - 50px);
            }

            &-image-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 100%;

                @include mobile {
                    padding: 50px;
                    min-width: 100%;
                }
            }

            &-devices-block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
            }

            &-devices-video {
                width: 265px;
                height: 500px;

                // background-color: red;
            }
        }
    }
}

.alienclick-bottom-block {
    background: rgba(255, 255, 255, 0.05);
}

.alienclick-tang {
    background: #0a0a0a;
    color: #fff;
}

.alienclick-rich {
    background: #00ff66;
    color: #fff;
}

.alienclick-penn {
    background: #187e41;
    color: #fff;
}

.alienclick-delft {
    background: #6c14dc;
    color: #fff;
}

.alienclick-light-delft {
    background: #300a60;
    color: #fff;
}

.alienclick__top-logo-svg {
    width: 132px;
    height: 43px;
}

.alienclick-animate-in {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1s ease-out, opacity 1s ease-out;
}
