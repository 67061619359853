@import "../../../../../styles/variables";

.motion {
    &__content {
        display: flex;

        &-paragraph-description {
            padding-left: 30px;
        }

        &-item {
            list-style-type: disc;
            margin-bottom: 20px;
        }
    }
}

.motion {
    &__content {
        &-paragraphs {
            &.graphic {
                @include fromTablet {
                    width: 30%;
                }
            }
        }

        &-schema {
            &.graphic {
                @include fromTablet {
                    width: 70%;
                }
            }
        }
    }
}
