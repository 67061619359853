@import "../../../styles/variables";

.icon-block {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 50px;

        @include maxWidth850 {
            align-items: center;
            justify-content: center;
        }

        @include maxWidth850 {
            gap: 20px;
        }
    }
}
