@import "../../styles/variables";

// Common styles / mixins

$w2Transparent: rgba(
	$color: $cWhite,
	$alpha: 0.2,
);

@mixin elBgWithBorder {
	border-radius: 8px;
	border: 1px solid $w2Transparent;
	background: #161616;
}

@mixin elBgWithBorderHover2 {
    border-radius: 8px;
	border-color: $cGreen;
	background: #121e17;
}

.letsWork {
	padding: 20px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	overflow: hidden;
	margin-top: 100px;
	cursor: url('../../assets/hover-cursor.png'), pointer;
	@include tablet {
		margin-top: 80px;
	}

	@include mobile {
		margin-top: 0;
		padding: 20px;
		flex-direction: column;

		.mainBtn {
			width: 100%;
			margin-top: 15px;
            
		}
	}

	&__top {
		text-transform: uppercase;
		font-family: $fontDefaultMedium;
		font-size: 14px;
		color: $cGreen;
		font-style: italic;
		margin-bottom: 10px;

		@include tablet {
			margin-bottom: 5px;
		}
	}

	&__bg {
		@include elBgWithBorder;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}

	&__title {
		font-family: $fontMainBold;
		letter-spacing: 0.03em;
		font-size: 30px;
		color: $w5Transparent;
		line-height: 1.1;

		@include tablet {
			font-size: 20px;
		}
	}

	&__icon{
		width: 30px !important;
		height: 30px !important;
	}
	.mainBtn {
		background: $cGreen;
		cursor: url('../../assets/hover-cursor.png'), pointer;
  
		&:hover{
			background: $cGreen;
			
		}

		span{
			color: #1D1D1E;
			margin-right: 10px;
			
		}
	}

	& .letsWork__bg {
			transition: 0.3s all ease;
		}

	&:hover {
		border-radius: 8px;
		&  .letsWork__bg {
			@include elBgWithBorderHover2;
		}
		
	}
}


.engineering-hover{
    &:hover {
        background: $cGreen;
        & .letsWork__bg {
            @include elBgWithBorderHover2;
        }

        
    }
}

.design-hover{
    .mainBtn {
        background: #F3ED5F;
    }
    &:hover {
        
        & .letsWork__bg {
            border: 1px solid #F3ED5F;
            background: rgba(243, 237, 95, 0.06);
        }

        
    }
   

}

.outstaff-hover{
   
    &:hover {
        
        & .letsWork__bg {
            border: 1px solid #5585FF;
            background: rgba(85, 133, 255, 0.10);
            
            
        }

        
    }
}
