@import "../../styles/variables";

.policyPage {
    padding-bottom: 100px;

    @include mobile {
        padding-bottom: 40px;
    }

    &__pre {
        color: $cGreen;
        padding-top: 20px;
        font-size: 18px;

        @include mobile {
            padding-top: 15px;
            font-size: 16px;
        }
    }

    &__title {
        font-size: 42px;
        text-align: center;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        font-style: italic;
        margin: 100px 0 60px;
        font-family: $fontDefaultMedium;

        @include tablet {
            margin: 60px 0 40px;
            font-size: 32px;
        }

        @include mobile {
            margin: 40px 0 30px;
            font-size: 22px;
        }
    }
}

.policyContent {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.policyBlock {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.6);

    @include mobile {
        font-size: 10px;
    }

    &__title {
        font-style: italic;
        font-family: $fontDefaultMedium;
        font-size: 20px;
        color: $cGreen;
        margin-bottom: 20px;
        text-transform: uppercase;

        @include mobile {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    &__list {
        list-style: disc;
        margin-left: 25px;

        @include mobile {
            margin-left: 14px;
        }
    }
}
