@import "../../styles/variables";

.contactsTitle {
    margin-bottom: 75px;

    @include mobile {
        margin-bottom: 40px;
    }
}

#contactsSection {
    > .wrapper {
        padding-top: 45px;

        @include mobile {
            padding-top: 25px;
        }
    }
    a {
        transition: 0.4s;
        cursor: url("../../assets/hover-cursor.png"), pointer;
        &:hover {
            color: $cGreen;
        }
    }
}

.contactsContent {
    padding-top: 40px;
    padding-bottom: 130px;

    @include mobile {
        padding-top: 0;
        padding-bottom: 60px;
    }

    .letsWork {
        margin-top: 70px;

        @include tablet {
            margin-top: 30px;
        }

        @include mobile {
            margin-top: 20px;
        }

        &__text {
            @include tablet {
                max-width: 312px;
            }
        }

        &__title {
            @include mobile {
                font-size: 18px;
            }
        }
    }
}

.contactsMap {
    padding: 0 40px;
    margin-bottom: 100px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    @include tablet {
        padding: 0;
        // margin: 0 -40px 60px;
    }

    @include mobile {
        // margin: 0 -20px 40px;
    }
}

.countrySvg {
    transition: 0.4s;
    cursor: url("../../assets/hover-cursor.png"), pointer;

    &.is-hidden {
        stroke-opacity: 0.2;
    }
}

.contactsMap__svg {
    &.is-active {
        .countrySvg:not(.is-hidden) {
            stroke-opacity: 1;
        }
    }
}

.countryPoint {
    transition: 0.4s;
    pointer-events: none;
   & svg {
        transform: none !important;
    }
    &.is-hidden {
        opacity: 0;
    }
}

.mapContacts {
    .contactsInfo {
        min-width: auto;
        display: inline-block;
        position: absolute;
        z-index: 10;
        font-size: 14px;
        background: rgba(13, 15, 14, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(2px);
        border-radius: 8px;
        padding: 12px;
        font-style: italic;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;

        @include mobile {
            font-size: 10px;
            padding: 7px 10px;
            margin-bottom: 0;

            .contactsInfo__link {
                margin-bottom: 5px;
            }

            .contactsInfo__link-svg {
                width: 11px;

                &:hover {
                    cursor: url("../../assets/hover-cursor.png"), pointer;
                }
            }
        }

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }

        .contactsInfo__link--phone {
            margin-bottom: 0;
        }

        &.usa {
            bottom: 33%;
            left: 16.7%;

            @include large {
                left: 15%;
            }

            @include standard {
                left: 13.7%;
            }

            @media screen and (max-width: 1440px) {
                left: 12.7%;
            }

            @include smallLaptop {
                left: 12.2%;
            }

            @include tablet {
                bottom: 30%;
                left: 10.4%;
            }

            @media screen and (max-width: 768px) {
                bottom: 27%;
                left: 6.5%;
            }

            @include mobile {
                left: 20%;
                top: 10px;
                bottom: auto;
            }
        }

        &.spain {
            bottom: 37%;
            right: 24.3%;

            @include large {
                right: 22.5%;
                bottom: 37%;
            }

            @include standard {
                right: 21.2%;
            }

            @media screen and (max-width: 1440px) {
                right: 20.2%;
            }

            @include smallLaptop {
                right: 19.7%;
                bottom: 36%;
            }

            @include tablet {
                bottom: 34%;
            }

            @media screen and (max-width: 768px) {
                right: 16.7%;
            }

            @include mobile {
                right: 34%;
                bottom: auto;
                top: 18px;
            }
        }

        &.ua {
            bottom: 54%;
            right: 6.8%;

            @include large {
                right: 5%;
            }

            @include standard {
                right: 3.5%;
            }

            @media screen and (max-width: 1440px) {
                right: 2.5%;
                bottom: 53%;
            }

            @include smallLaptop {
                right: 2%;
            }

            @include tablet {
                bottom: 51%;
            }

            @media screen and (max-width: 768px) {
                bottom: auto;
                right: 20%;
                top: 5px;
            }
        }

        &__triangle {
            width: 23px;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);

            display: none;

            @include tablet {
                display: none;
            }
        }
    }
}

.contactsInfo__link,
.contacts__mail {
    color: rgba(255, 255, 255, 0.5);
}

.contacts {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: 18px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    @include tablet {
        flex-direction: column;
    }

    @include mobile {
        font-size: 14px;
    }

    &__left {
        font-size: 20px;
        padding-left: 40px;

        @include smallLaptop {
            padding-left: 0;
        }

        @include tablet {
            margin-bottom: 40px;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &__main {
        font-family: $fontDefaultMedium;
        color: $cGreen;
        margin-bottom: 10px;
        line-height: 1.2;
        font-size: 30px;

        @include mobile {
            font-size: 15px;
        }
    }

    &__right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        width: 100%;
        max-width: 830px;

        @include tablet {
            justify-content: space-between;
            max-width: 100%;
        }
    }
}

.contactsInfo {
    min-width: 50%;
    margin-bottom: 30px;
    padding-left: 8%;

    @include smallLaptop {
        padding-left: 30px;
    }

    @include tablet {
        padding-left: 0;
        min-width: 285px;
    }

    @include mobile {
        margin-bottom: 20px;
    }

    &__title {
        display: flex;
        align-items: center;
        font-family: $fontDefaultMedium;
        margin-bottom: 6px;
        font-style: normal;

        @include mobile {
            // margin-bottom: 5px;
        }

        &-svg {
            margin-right: 6px;
            flex-shrink: 0;
            width: 22px;
            overflow: hidden;

            @include mobile {
                width: 16px;
            }
        }
    }

    &__link {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        &--phone {
            display: flex;
            align-items: center;
        }

        &-svg {
            width: 20px;
            margin-right: 6px;

            @include mobile {
                width: 16px;
            }
        }
    }
}
