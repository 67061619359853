@import "../../styles/variables";

.header {
    width: 100%;
    // position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    background: $cDark;
    z-index: 500;
    border-bottom: 1px solid transparent;
    transition: border 0.6s;

    @include mobile {
        top: 39px;

        .mainBtn {
            padding: 8px 10px;
        }
    }

    /*&.scrolled {
		border-bottom: 1px solid $cPageBorder;
	}*/

    &__logo {
        position: relative;
        z-index: 1000;
        width: 90px;

        cursor: url("../../assets/hover-cursor.png"), pointer;

        @include smallLaptop {
            width: 60px;
        }
    }
}

.headerContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @include smallLaptop {
        padding: 14px 0;
    }

    &__right {
        display: flex;
        align-items: center;
        cursor: url("../../assets/hover-cursor.png"), pointer;
    }

    &__letsWorkBtn {
        margin-right: 8px;

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-right: 10px;
        }

        svg {
            width: 25px !important;
            height: 25px !important;
        }
    }
}

.supportLink {
    display: flex;
    align-items: center;
    color: $cGrey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity $timeSm;
    cursor: url("../../assets/hover-cursor.png"), pointer;
    @include smallLaptop {
        position: static;
        transform: none;
    }

    @include mobile {
        z-index: 101;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #000;
        padding: 9px;
        justify-content: center;
        font-size: 12px;
    }

    &__text {
        display: block;
        transition: 0.4s;
        transform: translateY(7px);

        &--donate {
            font-size: 12px;
            opacity: 0;
            margin-top: 3px;
            margin-left: 2px;

            @include mobile {
                font-size: 9px;
            }
        }
    }

    &:hover {
        .supportLink__text {
            color: #5585ff;
            transform: translateY(0);
        }

        .supportLink__text--donate {
            opacity: 1;
            color: #f3ed5f;
        }
    }

    &__svg {
        margin-right: 16px;
        width: 16px;

        @include mobile {
            width: 14px;
            margin-right: 5px;
        }
    }
}

.burgerBtn {
    padding: 16px 24px;
    border-radius: 20px;
    background: transparent;
    transition: background $timeSm;
    position: relative;
    z-index: 101;
    cursor: url("../../assets/hover-cursor.png"), pointer;
    @include smallLaptop {
        padding: 12px 18px;
        border-radius: 16px;
    }

    @include mobile {
        padding: 12px 10px;
        border-radius: 12px;
    }

    &__container {
        width: 22px;
        height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        // overflow: hidden;

        @include mobile {
            // height: 14px;
            // width: 18px;
        }
    }

    &:hover {
        background: $cDarker;
    }

    &__line {
        width: 100%;
        height: 3px;
        background: $cWhite;
        border-radius: 20px;

        // transition: transform 0.4s;

        @include smallLaptop {
            height: 2px;
        }
    }

    &--close {
        background: $cWhite;
        z-index: 1000;
        .burgerBtn__container {
            transform: translateX(-50%);
        }

        .burgerBtn__line {
            background: $cDark;
            position: absolute;
            top: 50%;
            left: 50%;

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
            }

            &:nth-child(3) {
                transform: scale(0);
            }
        }

        &:hover {
            background: $cWhite;
        }
    }
}
