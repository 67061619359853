@import "../../../../styles/variables";

.devops {
    &__wrapper {
        background: #161616;
        border: 1px solid $cPageBorder;
        border-radius: 25px 25px 0px 0px;
        padding: 60px 100px;

        @include smallLaptop {
            padding: 60px 40px;
        }

        @include mobile {
            padding: 40px 20px;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $fontMainBold;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 16px;

        @include mobile {
            margin-bottom: 24px;
        }

        .servicesItem__icon {
            background: rgba($color: rgb(65, 181, 111), $alpha: 0.1);
        }
    }

    &__description {
        font-family: $fontDefaultLight;
        font-style: italic;
        font-size: 18px;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.4);
        margin-bottom: 60px;
        width: 60%;

        @include mobile {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px 100px;

        &-paragraphs {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 40%;

            @include tablet {
                width: 100%;
            }
        }

        &-paragraph {
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: rgba(255, 255, 255, 0.4);

            @include mobile {
                width: 100%;
            }

            &-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: rgba(255, 255, 255, 0.8);
            }
        }

        &-schema {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            // @include maxWidth850 {
            //   display: none;
            // }

            @include fromTablet {
                width: 60%;
            }
        }

        @include fromTablet {
            flex-direction: row;
        }
    }
}
