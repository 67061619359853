@import "../../styles/variables";

.navigationMenu {
    background: $cGreen;
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 900;
    opacity: 0;
    visibility: hidden;
    padding: 88px 0;
    transition: 0.3s ease-out;
    overflow: hidden;

    @include mobile {
        padding: 45px 0;
    }

    &.opened {
        opacity: 1;
        visibility: visible;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
}

.menuList {
    display: flex;
    flex-direction: column;
    margin: auto 0;
}

.menuItem {
    padding-bottom: 10px;

    @include tablet {
        padding-bottom: 15px;
    }

    &__title {
        display: flex;
        align-items: center;
        cursor: url("../../assets/hover-cursor.png"), pointer;
        &.active {
            .menuItem__triangle {
                transform: rotate(180deg);
            }
        }

        &:hover {
            .menuItem__titleName,
            .menuItem__triangle {
                opacity: 0.8;
            }
        }
    }

    &__titleNum {
        font-size: 24px;
        font-style: italic;
        color: rgba(255, 255, 255, 0.2);
        display: inline-block;
        margin-top: 15px;
        margin-right: 44px;

        @include tablet {
            margin-top: 8px;
            margin-right: 17px;
        }

        @include mobile {
            font-size: 18px;
            margin-top: 3px;
            margin-right: 10px;
        }
    }

    &__titleName {
        font-size: 60px;
        font-family: $fontMainBold;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        line-height: 1;
        transition: 0.3s;

        @include tablet {
            font-size: 40px;
        }

        @include mobile {
            font-size: 24px;
        }
    }

    &__triangle {
        margin-left: 14px;
        transition: 0.3s;
        width: 14px;

        @include tablet {
            width: 12px;
            margin-left: 6px;
        }

        @include mobile {
            width: 9px;
        }
    }
}

.submenu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    transform: translateX(100%);
    height: 0;

    &__content {
        padding-top: 20px;
        padding-left: 73px;

        @include tablet {
            padding-top: 15px;
            padding-left: 48px;
        }

        @include mobile {
            padding-left: 35px;
        }
    }

    &__button {
        padding: 8px 20px;
        background: rgba(255, 255, 255, 0.14);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 14px;
        font-style: italic;
        margin-bottom: 8px;
        color: white;

        @include tablet {
            padding: 6px 12px;
            font-size: 14px;
            border-radius: 10px;
        }

        @include mobile {
            padding: 4px 8px;
            font-size: 12px;
        }
    }

    &__item {
        margin-bottom: 8px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.navigationLangWrapper {
    width: 100%;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    right: 0;
}

.navigationLang {
    display: flex;
    align-items: center;
    color: rgba(16, 16, 17, 0.8);
    font-style: italic;
    font-size: 18px;
    margin-left: auto;

    @include mobile {
        font-size: 16px;
        justify-content: space-between;
        width: 100%;
    }

    &__item {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            color: $cWhite;
        }
    }
}
