@import "./styles/reset";
@import "./assets/fonts/fonts";
@import "./styles/variables";
@import "./styles/default";

*::-webkit-media-controls-panel {
    display: none !important;

    -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
    display: none !important;

    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;

    -webkit-appearance: none;
}

body {
    background-color: $cDark;
    color: $cWhite;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
    
}

.main {
    position: relative;
    padding-top: 89px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include smallLaptop {
        padding-top: 69px;
    }

    @include mobile {
        padding-top: 108px;
    }
}

.pageSection {
    > .wrapper {
        height: 100%;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-top: 45px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    > .client-wrapper {
        padding-top: 0;
    }
}

#homeSection {
    position: relative;
    height: calc(100vh - 130px);

    @include smallLaptop {
        height: calc(100vh - 68px);
    }

    @include mobile {
        height: calc(100vh - 107px);
    }
}

#servicesSection {
    position: relative;

    > .wrapper {
        overflow: unset;
    }
}

.transitionPage {
    position: relative;
    min-height: calc(100vh - 107px);

    @include tablet {
        min-height: auto;
    }

    @include mobile {
        border-radius: 15px 15px 0px 0px;
    }
}

.MuiPaper-root {
    &::-webkit-scrollbar {
        width: 10px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: #41b56f !important;
        border-radius: 20px !important;
    }
    &::-webkit-scrollbar-track {
        background: #222222 !important;
        border-radius: 20px !important;
    }
}

.disable-scroll {
    overflow: hidden;
}

