@import "../../styles/variables";

#communitySection {
    background: $cDark;
}

.communityWrapper {
    height: 100%;
    position: relative;
    padding-bottom: 385px;
    overflow: hidden;

    opacity: 1;
    transition: 0.4s 3.4s;

    > .wrapper {
        padding-top: 45px;
        position: relative;
        z-index: 100;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include mobile {
            padding-top: 25px;
        }
    }
}

.community {
    margin: auto;
    width: 50%;

    @include tablet {
        width: 100%;
    }

    opacity: 1;
    transition: 0.9s 1.1s;
}

.communityTitle {
    padding-top: 30px;
    margin-bottom: 75px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.communityChoose {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    margin: 0 auto 80px;

    @include tablet {
        margin-bottom: 60px;
    }

    @include mobile {
        margin-bottom: 40px;
    }

    &__title {
        font-family: $fontDefaultMedium;
        font-size: 42px;
        line-height: 1.2;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        font-style: italic;
        color: rgba($color: $cWhite, $alpha: 0.8);
        margin-bottom: 40px;

        @include mobile {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    .mainBtn {
        width: 100%;
        background: #222222;
        margin-top: 24px;
        padding: 16px;
        cursor: url("../../assets/hover-cursor.png"), pointer;
        @include mobile {
            margin-top: 16px;
            height: 45px;
        }

        &:hover {
            background: #222222;
        }
    }
}

.chooseItem {
    width: calc(50% - 8px);
    @include mobile {
        width: 100%;
    }
    &__label {
        background: rgba(22, 22, 22, 1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        width: 100%;
        padding: 24px 30px;
        cursor: url("../../assets/hover-cursor.png"), pointer;
        position: relative;
        font-style: italic;

        transition: 0.4s;

        > input {
            position: absolute;
        }

        &:hover {
            border-color: $cGreen;
        }

        &.active {
            border-color: $cGreen;
            background: rgba($color: #202020, $alpha: 1);
        }

        @include mobile {
            display: flex;
            align-items: center;
            padding: 14px 20px;
            border-radius: 14px;
        }
    }

    &__icons {
        display: flex;
        align-items: center;
        margin-bottom: 11px;

        @include mobile {
            width: 54px;
            flex-shrink: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -10px;
            margin-right: 20px;
        }
    }

    &__icon {
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 10px;
        overflow: hidden;

        @include mobile {
            flex-shrink: 0;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__name {
        font-family: $fontDefaultMedium;
        display: block;
        font-size: 20px;
        margin-bottom: 10px;

        @include mobile {
            font-size: 18px;
            margin-bottom: 2px;
        }
    }

    &__text {
        color: $cGrey;

        @include mobile {
            font-size: 14px;
            line-height: 1.2;
        }
    }
}

.communityImage {
    width: 570px;
    height: 285px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: calc(100% - 385px);
    transform: translateX(-50%);
    transition: 1s;

    // top: 0;
    // width: 100%;
    // height: 100vh;

    @include mobile {
        max-width: 720px;
        width: 100%;
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        svg {
            flex-shrink: 0;
            height: auto !important;

            @include tablet {
                width: auto !important;
                height: 100% !important;
            }
        }
    }

    img {
        object-fit: cover;
    }
}

.pageSection.exit-active {
    position: relative;
    z-index: 100000;
    pointer-events: none;
    
    .communityWrapper {
        opacity: 0;
    }

    .community {
        opacity: 0;
    }

    .communityImage {
        top: 0;
        width: 100%;
        height: 100vh;
    }

    .chooseItem__label.active,
    .chooseItem__label,
    .communityBtn {
        background: rgba(22, 22, 22, 0.7);
    }
}
