@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@1,800&display=swap");
@font-face {
    font-family: "InterLight";
    src: url("Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "InterRegular";
    src: url("Inter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "InterMedium";
    src: url("Inter-Medium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "KanitSemiBold";
    src: url("KanitSemiBold.eot");
    src: url("KanitSemiBold.eot") format("embedded-opentype"), url("KanitSemiBold.woff2") format("woff2"),
        url("KanitSemiBold.woff") format("woff"), url("KanitSemiBold.ttf") format("truetype"),
        url("KanitSemiBold.svg#KanitSemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "OverpassRegular";
    src: url("Overpass-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "MontseratBold";
    src: url("Overpass-Regular.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "WallpoetRegular";
    src: url("Wallpoet-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BaiJamjuree";
    src: url("BaiJamjuree-SemiBoldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "ComfortaBold";
    src: url("Comfortaa-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "ComfortaRegular";
    src: url("Comfortaa-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "BaiJamjureeLightItalic";
    src: url("BaiJamjuree-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
