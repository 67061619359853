@import "../../../styles/variables";

.actionForm {
    &__radioGroup {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 16px;
    }
}

.radioGroup {
    &__title {
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        line-height: 150%;
    }

    &__groupBlock {
        display: flex;
        gap: 10px;

    }

    &__groupItem {
        padding: 8px 22px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        cursor: url("../../../assets/hover-cursor.png"), pointer;
        width: 100%;
        height: 35px;
        text-align: center;

        @media screen and (min-width: 480px) {
            padding: 8px 16px;
            width: fit-content;
        }
    }
}

.activeRadioItem {
    border-color: rgba(65, 181, 111, 0.8);
    color: #fff;
}

.radio-group-wrap{
    flex-wrap: wrap;
}