@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.cookies-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(16, 16, 17, 0.6);
    z-index: 1000;
    &.hidden {
        animation: fadeOut 0.5s;
        animation-fill-mode: forwards;
        pointer-events: none;
    }

    &__wrapper {
        position: absolute;
        left: 40px;
        bottom: 110px;
        z-index: 100;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #41b56f;
        background: #202020;
        padding: 20px 30px;
    }

    &__icon-wrapper {
        margin-bottom: 15px;

        &__icon {
            width: 50px;
            height: 50px;
        }
    }

    &__text {
        font-size: 18px;
        font-family: InterRegular;
        font-style: italic;
        font-weight: 400;
        line-height: 27px;
        color: #fff;
        max-width: 230px;
        margin-bottom: 20px;
    }
    &__button {
        display: inline-flex;
        padding: 10px 22px;
        align-items: center;
        border-radius: 16px;
        background: #41b56f;
        cursor: url(../../assets/hover-cursor.png), pointer;
        transition: background 0.3s;
        &:hover {
            background: #328c56;
        }

        font-family: InterRegular;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
    }
}
