@import "../../../../styles/variables";

.icon-block {
    &__hand {
        opacity: 0;
        position: absolute;
        left: -130px;
        top: 35px;
        width: 120px;
        height: 60px;
        transition: 0.2s opacity ease-in;

        // &.is-visible {
        // 	opacity: 0;

        // 	@include fromTablet {
        // 		opacity: 1;
        // 	}
        // }

        @include maxWidth850 {
            display: none;
        }
    }

    &__row {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: 0.2s all ease-in;
        filter: grayscale(100%);
        cursor: default;

        &:hover {
            filter: grayscale(0%);

            .icon-block__row-title {
                color: rgba(255, 255, 255, 1);
            }

            .icon-block__hand {
                opacity: 1;
            }
        }

        @include maxWidth850 {
            filter: grayscale(0%);
        }

        // &.colorful {
        //   filter: grayscale(0%) !important;
        // }

        &-title {
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.8);
            transition: 0.2s all ease-in;
        }

        &-icons {
            display: flex;
            flex-wrap: wrap;
            gap: 18px;

            @include mobileMiddle {
                gap: 10px;
            }
        }

        &-icon {
            width: 60px;
            height: 60px;

            @include mobileMiddle {
                width: 45px;
                height: 45px;
            }
        }
    }
}
