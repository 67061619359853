@import "../../../../../styles/variables";

.devops {
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        &-paragraph-description {
            padding-left: 30px;
        }

        &-item {
            list-style-type: disc;
            margin-bottom: 20px;
        }
    }
}

.phase {
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
