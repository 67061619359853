@import "../../styles/variables";

#homeSection {
    height: 80vh;
    > .wrapper {
        position: relative;
        overflow-y: hidden;
    }
}

.aboutTitle {
    padding: 20px 0 0;
    font-size: 18px;
    border-radius: 25px 25px 0px 0px;
    overflow: hidden;
    color: $w4Transparent;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 5px;
    }

    @include mobile {
        padding-top: 20px;
        font-size: 16px;
    }
}

.titleItem {
    height: auto;

    @include mobile {
        height: 48px;
        width: 100%;
    }

    &.digital {
        .titleImg img {
            object-position: center 70%;
        }

        .titleText {
            @include mobile {
                flex-direction: row;
                align-items: baseline;
                // display: inline-flex;
            }

            @media screen and (max-width: 420px) {
                flex-direction: column;
                align-items: unset;
            }

            &__desc:not(.titleText__desc--before) {
                @include mobile {
                    margin-left: auto;
                }

                @media screen and (max-width: 420px) {
                    margin-right: 12px;
                }
            }
        }
    }
}

.titleText {
    display: flex;
    align-items: baseline;

    @include mobile {
        height: 48px;
        flex-direction: column;
        align-items: unset;
        display: inline-flex;
    }

    &__before {
        display: flex;
        align-items: baseline;
        position: relative;

        @include tablet {
            padding-bottom: 5px;
        }
    }

    &__main {
        margin-right: 10px;
        display: flex;
        align-items: center;
        font-size: 120px;
        -webkit-text-stroke: 1px transparent;
        text-stroke: 1px transparent;
        margin-left: 0;

        transition: text-stroke 0.6s, -webkit-text-stroke 0.6s, color 0.6s, opacity 0.6s,
            margin-left 0.6s cubic-bezier(0.2, 0.5, 0.5, 1.15);

        @include maxHeight850 {
            font-size: 80px;
        }

        @include tablet {
            font-size: 80px;
            transition: 0.4s ease-out;

            .titleText__mainSvg {
                margin-left: 20px;
                margin-right: -34px;
                max-width: 14px;
                flex-shrink: 0;
                transform: translateX(0) scale(1);
                opacity: 1;
                visibility: visible;
                transition: 0.4s ease-out;
            }
        }

        @include mobile {
            font-size: 52px;
            margin-right: 0;

            .titleText__mainSvg {
                margin-left: 8px;
                margin-right: -10px;
                max-width: 10px;
                transform: rotate(0);
            }
        }
    }

    &__desc {
        white-space: nowrap;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        @include mobile {
            padding-bottom: 2px;
        }

        &-h3 {
            width: 0;
            padding-bottom: 6px;
            overflow: hidden;
        }

        span {
            margin-left: -3px;
            opacity: 0;
            display: inline-block;
            visibility: hidden;
            transition: visibility 0s 0.4s;
            margin-bottom: 6px;
        }

        &--before {
            position: absolute;
            bottom: 1px;
            left: 0;

            @include tablet {
                bottom: 6px;
            }

            @include mobile {
                bottom: 0;
            }

            span {
                margin-left: -12px;

                @include maxHeight850 {
                    margin-left: -3px;
                }

                @include smallLaptop {
                    margin-left: -3px;
                }
            }
        }
    }
}

.titleImg {
    position: absolute;
    width: 130px;
    height: 130px;
    overflow: hidden;
    transition: opacity 0.4s, visibility 0.4s;

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: 0.6s;
    }

    &--focus {
        // bottom: 75px;
        top: calc(100% - 206px);
        right: 40px;
        transform: rotate(-30deg);
        z-index: 2;

        @include mobile {
            top: calc(100% - 216px);
        }
    }

    &--digital {
        top: calc(100% - 226px);
        right: 146px;
        transform: rotate(4deg);
        z-index: 3;

        @include mobile {
            top: calc(100% - 236px);
        }
    }

    &--syndicate {
        top: calc(100% - 316px);
        right: 78px;
        transform: rotate(11deg);
        z-index: 1;

        @include mobile {
            top: calc(100% - 326px);
        }
    }
}

// Main screen hover effect
.titleItem {
    &.hovered {
        .titleText__desc span {
            visibility: visible;
            transition: visibility 0s 0s;
        }

        &.digital {
            .titleText__main {
                margin-left: 277px;
                text-stroke: 1px transparent;

                @include maxHeight850 {
                    margin-left: 138px;
                }

                @include smallLaptop {
                    margin-left: 138px;
                }

                @include mobile {
                    margin-left: 110px;
                }
            }
        }

        .titleText__before {
            z-index: 20;
        }

        @include tablet {
            .titleText__mainSvg {
                transform: translateX(50px) scale(0);
                opacity: 0;
                visibility: hidden;
                // width: 0;

                @include mobile {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.mainTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: url("../../assets/hover-cursor.png"), default;

    &.active {
        .titleItem {
            &:not(.hovered) {
                .titleText__main {
                    -webkit-text-stroke: 1px $cWhite;
                    text-stroke: 1px $cWhite;
                    color: $cDark;
                    opacity: 0.14;
                }

                .titleImg {
                    opacity: 0;
                    visibility: hidden;
                }

                .titleText__desc {
                    opacity: 0.14;
                }
            }
        }
    }
}

.main-animation-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 840px;
}
